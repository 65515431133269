import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "../../components/Common/TableContainer";
import { toast, ToastContainer } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";
import Loader from "components/Common/Loader";
import { getBulkQueryDetails, getRetailQueryDetails } from "services/ApiService";

function Query() {
  const [retailQuery, setRetailQuery] = useState([]);
  const [bulkQuery, setBulkQuery] = useState([]);
  const [isFilterApply, setIsFilterApply] = useState(false);
  const [filterQueryData, setFilterQueryData] = useState([]);
  const [isRetailQuery, setIsRetailQuery] = useState(
    parseInt(localStorage.getItem("isRetailQuery")) || 1
  );
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeQueryTab") || "not replied"
  );
  const [selectedYear, setSelectedYear] = useState(null); // New state for year
  const [selectedMonth, setSelectedMonth] = useState(null); // New state for month

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December",
  ];

  // Generate years (e.g., last 5 years from current year)
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 5 }, (_, i) => currentYear - i);

  const fetchRetailQuery = () => {
    getRetailQueryDetails()
      .then(res => {
        setRetailQuery(res.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error("Error while fetching retail query details:", error);
        toast.error("Failed to fetch retail query details");
      });
  };

  const fetchBulkQuery = () => {
    getBulkQueryDetails()
      .then(res => {
        console.log("bulk query data...", res);
        setBulkQuery(res.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error("Error while fetching bulk query details:", error);
        toast.error("Failed to fetch bulk query details");
      });
  };

  useEffect(() => {
    fetchRetailQuery();
    fetchBulkQuery();
  }, []);

  const handleViewQueryDetails = value => {
    console.log("row.original data :- ", value.queryId);
    localStorage.setItem("queryId", value.queryId);
    window.location.href = "/query-details";
  };

  const handleRetailQuery = () => {
    localStorage.setItem("isRetailQuery", 1);
    setIsRetailQuery(1);
  };

  const handleBulkQuery = () => {
    localStorage.setItem("isRetailQuery", 0);
    setIsRetailQuery(0);
  };

  const queryData = isRetailQuery === 1 ? retailQuery : bulkQuery;

  const filterQuery = (year, month) => {
    setIsFilterApply(true);
    console.log("Selected Year:", year, "Selected Month:", month);

    const filteredData = queryData.filter(query => {
      const [day, monthNum, queryYear] = query.date.split("/"); // Split "DD/MM/YYYY"
      const monthIndex = parseInt(monthNum, 10) - 1; // Convert MM to 0-based index
      const queryMonthName = months[monthIndex];
      return (
        parseInt(queryYear, 10) === year && // Match year
        queryMonthName === month // Match month
      );
    });

    console.log("Filtered Data by Year and Month:", filteredData);

    // Apply tab-specific filtering
    const tabFilteredData = filteredData.filter(query =>
      activeTab === "not replied"
        ? query.isReplied === 0 && query.isconvertedToOrder === false
        : activeTab === "replied"
        ? query.isReplied === 1 && query.isconvertedToOrder === false
        : query.isconvertedToOrder === true
    );

    console.log("Tab Filtered Data:", tabFilteredData);
    setFilterQueryData(tabFilteredData); // Update state
  };

  const finalQueryData = queryData.filter(query =>
    activeTab === "not replied"
      ? query.isReplied === 0
      : activeTab === "replied"
      ? query.isReplied === 1 && query.isconvertedToOrder === false
      : query.isconvertedToOrder === true
  );

  console.log("filterQueryData (state):", filterQueryData);

  const columns = useMemo(
    () => [
      { Header: "User Name", accessor: "userName" },
      { Header: "Company Name", accessor: "companyName" },
      { Header: "Mobile / Email", accessor: "contact" },
      { Header: "Date", accessor: "date" },
      { Header: "Time", accessor: "time" },
      {
        Header: "View",
        Cell: ({ row }) => (
          <button
            className="btn p-0 text-black d-inline-block font-size-20 text-center border-0"
            style={{
              transition: "none",
              transform: "none",
              borderRadius: "5px",
            }}
            onClick={() => handleViewQueryDetails(row.original)}
          >
            <i className="mdi mdi-eye" style={{ lineHeight: "20px" }}></i>
          </button>
        ),
      },
    ],
    []
  );

  const handleTabClick = tabName => {
    window.location.reload();
    localStorage.setItem("activeQueryTab", tabName);
    setActiveTab(tabName);
    if (isFilterApply && selectedYear && selectedMonth) {
      // Re-apply filter with new tab
      filterQuery(selectedYear, selectedMonth);
    }
  };

  document.title = "Users | Stelo - Steel Trading Dashboard";

  return (
    <div className="page-content">
      <Breadcrumbs title="Query" breadcrumbItem="Query Details" />
      <ToastContainer />
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <ul className="row m-0 p-0">
            <li
              className={`col-6 form-tab1 font-size-12 d-flex justify-content-center align-items-center data-tabs ${
                isRetailQuery === 1 ? "text-white bg-primary" : "text-primary"
              }`}
              style={{ whiteSpace: "nowrap", padding: "8px 12px" }}
              onClick={handleRetailQuery}
            >
              Retail Query
            </li>
            <li
              className={`col-6 form-tab2 font-size-12 d-flex justify-content-center align-items-center data-tabs2 ${
                isRetailQuery === 0 ? "text-white bg-primary" : "text-primary"
              }`}
              style={{ whiteSpace: "nowrap", padding: "8px 12px" }}
              onClick={handleBulkQuery}
            >
              Bulk Query
            </li>
          </ul>

          {activeTab === "replied" ? (
            <div className="d-flex gap-2">
              <Dropdown>
                <Dropdown.Toggle
                  variant="primary"
                  id="dropdown-year"
                  className="px-4 py-2 rounded"
                >
                  {selectedYear || "Select Year"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow-lg">
                  {years.map((year, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => {
                        setSelectedYear(year);
                        if (selectedMonth) filterQuery(year, selectedMonth);
                      }}
                    >
                      {year}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown>
                <Dropdown.Toggle
                  variant="primary"
                  id="dropdown-month"
                  className="px-4 py-2 rounded"
                >
                  {selectedMonth || "Select Month"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow-lg">
                  {months.map((month, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => {
                        setSelectedMonth(month);
                        if (selectedYear) filterQuery(selectedYear, month);
                      }}
                    >
                      {month}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="row tab-div row justify-content-center">
          <div
            className={`col text-center py-2 tab-item ${
              activeTab === "not replied" ? "tab-active" : ""
            }`}
            onClick={() => {
              isRetailQuery ? fetchRetailQuery() : fetchBulkQuery();
              handleTabClick("not replied");
            }}
          >
            Queries
          </div>
          <div
            className={`col text-center py-2 tab-item ${
              activeTab === "replied" ? "tab-active" : ""
            }`}
            onClick={() => {
              isRetailQuery ? fetchRetailQuery() : fetchBulkQuery();
              handleTabClick("replied");
            }}
          >
            Replied Queries
          </div>
          <div
            className={`col text-center py-2 tab-item ${
              activeTab === "converted" ? "tab-active" : ""
            }`}
            onClick={() => {
              isRetailQuery ? fetchRetailQuery() : fetchBulkQuery();
              handleTabClick("converted");
            }}
          >
            Converted Queries
          </div>
        </div>

        <div>
          {isLoading ? (
            <Loader />
          ) : queryData.length > 0 ? (
            <TableContainer
              columns={columns}
              data={isFilterApply ? filterQueryData : finalQueryData}
              isGlobalFilter={true}
              isAddOptions={false}
              customPageSize={10}
              isPagination={true}
              tableClass="align-middle table-nowrap table-check table table-div"
              theadClass="table-light"
              paginationDiv="col-12"
              pagination="justify-content-center pagination pagination-rounded"
            />
          ) : (
            <div className="text-center mt-4">
              <h5>No Data Found</h5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Query.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default Query;