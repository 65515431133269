import React, { useCallback, useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addMessage,
  converToRepliedQuery,
  editQueryItem,
  editQueryReply,
  editRateToNA,
  getMessage,
  getQueryDetails,
} from "services/ApiService"
import { toast, ToastContainer } from "react-toastify"
import { useNavigate } from "react-router-dom"
import "./query.css"
import { orderImage_url, queryImg } from "utils/APIUrls"
import { debounce } from "lodash"

function QueryDetail() {
  const [data, setData] = useState([])
  const [message, setMessage] = useState([])
  const [rate, setRate] = useState(null)
  const [outerDiameter, setOuterDiameter] = useState(null)
  const [innerDiameter, setInnerDiameter] = useState(null)
  const [length, setLength] = useState(null)
  const [height, setHeight] = useState(null)
  const [width, setWidth] = useState(null)
  const [isDisabled, setIsDisabled] = useState()
  const [showSubmit, setShowSubmit] = useState(false)
  const [queryId, setQueryId] = useState(localStorage.getItem("queryId"))
  const [adminId, setAdminId] = useState(localStorage.getItem("adminId"))

  const [queryType, setQueryType] = useState()
  const [reply, setReply] = useState("")
  const [isView, setIsView] = useState(false)
  const [selectedImage, setSelectedImage] = useState("")
  const [queryNote, setQueryNote] = useState("")
  const [isNA, setIsNA] = useState(false)
  const [updatedItemObj, setUpdatedItemObj] = useState({})

  var navigate = useNavigate()

  console.log("Check is this Disabled...", isDisabled)

  var sendReply = () => {
    const reqObj = {
      channelId: null,
      queryId: queryId,
      senderId: adminId,
      type: 1,
      message: reply,
    }

    addMessage(reqObj)
      .then(res => {
        setReply("")
        getQueryMessage()
      })
      .catch(err => {
        console.log("err in reply send", err)
      })
    // console.log("reqObj...", reqObj)

    // editQueryReply(reqObj)
    //   .then(res => {
    //     setReply("")
    //     toast.success("Reply sent successfully!!")
    //     // console.log("QUery details Items", res.data)
    //   })
    //   .catch(err => {
    //     console.log("err in query detail", err)
    //   })
  }

  var getQueryMessage = () => {
    getMessage(queryId)
      .then(res => {
        // console.log("messagesss:- ", res.data)
        setMessage(res.data)
      })
      .catch(err => {
        console.log("err in message get", err)
      })
  }

  var fetchData = () => {
    getQueryDetails(queryId)
      .then(res => {
        setData(res.data)
        setQueryNote(res.data[0].notes)
        console.log("Query Details ", res.data)
        console.log("QUery details IMAGE", selectedImage)
        setQueryType(res.data[0].queryType)
        setIsDisabled(res.data[0].isconvertedToOrder)
        // setUserId(res.data[0].userId)
        setSelectedImage(res.data[0].queryImage)
      })
      .catch(err => {
        console.log("err in query detail", err)
      })
  }

  useEffect(() => {
    fetchData()
    getQueryMessage()
  }, [queryId])
  // console.log("IsDisabled", isDisabled)

  var inputRateUpdate = value => {
    // console.log("reqObj...", value)
    editQueryItem(value)
      .then(res => {
        // toast.success("Rate updated successfully!!")
        // console.log("QUery details Items", res.data)
      })
      .catch(err => {
        console.log("err in query detail", err)
      })
  }

  var debouncedApi = useCallback(
    debounce(value => inputRateUpdate(value), 10),
    []
  )

  var itemUpdate = (
    queryId,
    itemId,
    newRate,
    newOuterDiameter,
    newInnerDiameter,
    newLength,
    newHeight,
    newWidth
  ) => {
    setUpdatedItemObj({})
    const reqObj = {
      queryId,
      itemId,
      rate: newRate === null ? null : newRate,
      outerDiameter: newOuterDiameter,
      innerDiameter: newInnerDiameter,
      length: newLength,
      height: newHeight,
      width: newWidth,
    }

    setData(prevData =>
      prevData.map(item =>
        item.queryItemId === itemId ? { ...item, ...reqObj } : item
      )
    )
    setUpdatedItemObj(reqObj)

    // console.log("Updated Item Object.......", data)

    // debouncedApi(reqObj)
  }
  useEffect(() => {
    console.log("Updated Item Object (latest value).......", data)
  }, [updatedItemObj])
  // console.log("Query Details Noteee", queryNote)

  const closeModal = () => {
    setIsView(false)
    // setSelectedImage("");
  }

  const showSubmitBtn = id => {

    // var isInvalidRate ;
    //  data.map(item => {
    //   const rateValue = item.rate;
    //   console.log("rate value in map..",rateValue)
    //   return (
    //     isInvalidRate = rateValue === null || rateValue === "" || isNaN(rateValue) || rateValue === "0" ||rateValue === 0 ? false : true
    //   );
    // });

    // console.log("Is Invalid Rate", isInvalidRate)

    // isInvalidRate ? toast.success("Query converted to order successfully!!") : toast.error("Please enter rate for all items")
    
    // if (condition) {
      
    // } else {
      
    // }
    console.log("dataaaa",data)
    const allRatesInvalid = data.some(item => {
      const rateValue = item.rate;
      console.log("rate value in map..",rateValue)
      return (
        rateValue === null ||
        rateValue === "" ||
        rateValue === 0 
      
      );
    });
  
    console.log("All Rates Invalid:", allRatesInvalid);
if (allRatesInvalid) {
  toast.error("Please fill a valid rate in all field.")
} else {
  
  var updatedObj = {
    itemObj:data
  }
  debouncedApi(updatedObj)

  console.log("Updated Item Object.......", updatedObj)
  converToRepliedQuery(id)
    .then(res => {
      console.log("Query converted to order", res.data)
      setShowSubmit(false)
      toast.success("Query converted to order successfully!!")
    })
    .catch(err => {
      console.log("err in query detail", err)
    })
}
    
  }

  //   console.log("Query id...", queryId)

  document.title = "Query | Stelo - Steel Trading Dashboard"

  return (
    <div className="page-content">
      <ToastContainer />

      {/* window.location.href = "/categories"  */}
      <Breadcrumbs title="Query" breadcrumbItem="Query Details" />
      <button
        className="btn btn-secondary mb-3"
        style={{
          padding: "10px 29px",
          borderRadius: "8px",
          backgroundColor: "#`6c757d",
          border: "none",
          color: "#fff",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        onClick={() => navigate(-1)}
        onMouseOver={e => {
          e.target.style.backgroundColor = "#5a6268"
        }}
        onMouseOut={e => {
          e.target.style.backgroundColor = "#6c757d"
        }}
      >
        Back
      </button>
      {/* Back Button */}

      <div
        className="content-container"
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          padding: "20px",
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Note Section */}
        {isDisabled ? (
          <h4 className="mb-4 font-weight-bold text-danger text-center">
            This query is converted into order
          </h4>
        ) : (
          ""
        )}
        <div
          className="note-section mb-4 p-3"
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            borderRadius: "8px",
            opacity: isDisabled ? "0.5" : "1",
          }}
        >
          <h5 className="font-weight-bold mb-2">Note:</h5>
          <p style={{ margin: 0, color: "#555" }}>{queryNote}</p>
        </div>

        {queryType == 1 ? (
          <div
            className="table-section mb-5 "
            style={{
              overflowX: "auto",
            }}
          >
            <table
              className="table table-hover table-striped align-middle "
              style={{
                pointerEvents: isDisabled ? "none" : "auto",
                opacity: isDisabled ? "0.5" : "1",
              }}
            >
              <thead
                className="table-header"
                style={{
                  backgroundColor: "#007bff",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <tr>
                  <th>Category</th>
                  <th>Material</th>
                  <th>Inner Diameter</th>
                  <th>Outer Diameter</th>
                  <th>Width</th>
                  <th>Height</th>
                  <th>Length</th>
                  <th>Quantity</th>
                  <th>Rate</th>
                  {data[0].isRetail ? <th>weight</th> : ""}
                </tr>
              </thead>
              <tbody>
                {data.map((item, indx) => (
                  <tr key={indx} style={{ textAlign: "center" }}>
                    <td>{item.category}</td>
                    <td>{item.material}</td>

                    <td>
                      <div className="d-flex align-items-center position-relative">
                        <input
                          type="text"
                          value={item.innerDiameter}
                          style={{
                            minWidth: "100px",
                          }}
                          className={`form-control pe-5 ${
                            item.isInnerDiameterEdited === 1
                              ? "bg-danger text-white"
                              : ""
                          }`}
                          onChange={e => {
                            setInnerDiameter(e.target.value)
                            itemUpdate(
                              item.queryId,
                              item.queryItemId,
                              rate,
                              outerDiameter,
                              e.target.value,
                              length,
                              height,
                              width
                            )
                          }}
                        />
                      </div>
                    </td>
                    {/* <td>
                      {item.outerDiameter
                        ? `${item.outerDiameter} ${
                            item.outerDiameterUnit
                              ? item.outerDiameterUnit
                              : "mm"
                          }`
                        : "-"}
                    </td> */}
                    <td>
                      <div className="d-flex align-items-center position-relative ">
                        <input
                          type="text"
                          value={item.outerDiameter}
                          style={{
                            minWidth: "100px",
                          }}
                          className={`form-control pe-5 ${
                            item.isOuterDiameterEdited === 1
                              ? "bg-danger text-white"
                              : ""
                          }`}
                          onChange={e => {
                            // setInnerDiameter(e.target.value)
                            itemUpdate(
                              item.queryId,
                              item.queryItemId,
                              rate,
                              e.target.value,
                              innerDiameter,
                              length,
                              height,
                              width
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      {/* {item.width
                        ? `${item.width} ${
                            item.widthUnit ? item.widthUnit : "mm"
                          }`
                        : "-"} */}

                      <input
                        type="text"
                        value={item.width}
                        style={{
                          minWidth: "100px",
                        }}
                        className={`form-control pe-5 ${
                          item.isWidthEdited === 1 ? "bg-danger text-white" : ""
                        }`}
                        onChange={e => {
                          // setInnerDiameter(e.target.value)
                          itemUpdate(
                            item.queryId,
                            item.queryItemId,
                            rate,
                            outerDiameter,
                            innerDiameter,
                            length,
                            height,
                            e.target.value
                          )
                        }}
                      />
                    </td>
                    <td>
                      {/* {item.height
                        ? `${item.height}   ${
                            item.heightUnit ? item.heightUnit : "mm"
                          }`
                        : "-"} */}

                      <input
                        type="text"
                        value={item.height}
                        style={{
                          minWidth: "100px",
                        }}
                        className={`form-control pe-5 ${
                          item.isHeightEdited === 1
                            ? "bg-danger text-white"
                            : ""
                        }`}
                        onChange={e => {
                          // setInnerDiameter(e.target.value)
                          itemUpdate(
                            item.queryId,
                            item.queryItemId,
                            rate,
                            outerDiameter,
                            innerDiameter,
                            length,
                            e.target.value,
                            width
                          )
                        }}
                      />
                    </td>
                    <td>
                      {/* {item.length
                        ? `${item.length} ${
                            item.lengthUnit ? item.lengthUnit : "mm"
                          }`
                        : "-"} */}

                      <input
                        type="text"
                        value={item.length}
                        style={{
                          minWidth: "100px",
                        }}
                        className={`form-control pe-5 ${
                          item.isLengthEdited === 1
                            ? "bg-danger text-white"
                            : ""
                        }`}
                        onChange={e => {
                          // setInnerDiameter(e.target.value)
                          itemUpdate(
                            item.queryId,
                            item.queryItemId,
                            rate,
                            outerDiameter,
                            innerDiameter,
                            e.target.value,
                            height,
                            width
                          )
                        }}
                      />
                    </td>

                    <td>
                      {item.quantity
                        ? `${item.quantity} ${
                            item.quantityUnit
                              ? item.quantityUnit.toLowerCase() == "number"
                                ? "Pcs"
                                : item.quantityUnit.toLowerCase() == "kg"
                                ? "kg"
                                : item.quantityUnit.toLowerCase() == "ton"
                                ? "ton"
                                : "kg"
                              : item.isRetail
                              ? "Pcs"
                              : "kg"
                          }`
                        : "-"}
                    </td>

                    <td>
                      <div className="d-flex align-items-center position-relative">
                        <input
                          type="text"
                          value={item.rate}
                          
                          style={{
                            minWidth: "150px",
                          }}
                          className="form-control pe-5"
                          onChange={e =>
                            itemUpdate(
                              item.queryId,
                              item.queryItemId,
                              e.target.value == null ? null : e.target.value,
                              outerDiameter,
                              innerDiameter,
                              length,
                              height,
                              width
                            )
                          }
                        />
                        {!item.isRateNA ? (
                          <button
                            className="btn btn-primary py-1 position-absolute top-50 end-0 translate-middle-y me-2"
                            onClick={() => {
                              editRateToNA(item.queryItemId, 1).then(res => {
                                fetchData()
                              })
                            }}
                          >
                            N/A
                          </button>
                        ) : (
                          <button
                            className="btn btn-danger py-1 position-absolute top-50 end-0 translate-middle-y me-2"
                            onClick={() => {
                              editRateToNA(item.queryItemId, 0).then(res => {
                                fetchData()
                              })
                            }}
                          >
                            X
                          </button>
                        )}
                      </div>
                    </td>
                    <td>
                      {data[0].isRetail
                        ? item.weight
                          ? `${item.weight} ${
                              item.weightUnit ? item.weightUnit : "kg"
                            }`
                          : "-"
                        : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="d-flex justify-content-center border w-full">
              <button
                className="border-0 mt-1 px-4 py-2 btn-primary text-white rounded "
                onClick={() => showSubmitBtn(queryId)}
              >
                Convert TO Replied Query
              </button>
            </div>
          </div>
        ) : queryType == 2 ? (
          <div className="mb-4 d-flex align-items-center justify-content-center">
            <div className="text-center">
              <div className="query-img-div">
                <img
                  src={`${orderImage_url}${selectedImage}`}
                  className="query-img"
                  alt=""
                />
              </div>
              <button
                className="custom-button view-button"
                onClick={() => {
                  setIsView(true)
                }}
              >
                View
              </button>
              <button className="custom-button download-button">
                Download
              </button>
            </div>
            {isView && (
              <div
                className="modal-overlay"
                style={{
                  position: "fixed",
                  marginTop: "70px",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "93%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: "1000",
                }}
              >
                <div
                  className="modal-content"
                  style={{
                    backgroundColor: "#fff",
                    padding: "20px",
                    borderRadius: "8px",
                    maxWidth: "600px",
                    textAlign: "center",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <img
                    src={`${queryImg}${selectedImage}`}
                    alt="Modal"
                    style={{
                      maxWidth: "100%",
                      borderRadius: "8px",
                    }}
                  />
                  <button
                    onClick={closeModal}
                    style={{
                      backgroundColor: "#007bff",
                      color: "#fff",
                      padding: "10px 20px",
                      borderRadius: "8px",
                      marginTop: "20px",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          console.log("Note queryyy")
        )}

        {/* Query Table */}

        {/* Reply Section */}
        <div className="">
          <div
            className={`message-section mt-4 ${isDisabled ? "opacity-50" : ""}`}
            style={{
              backgroundColor: "#fff",
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              maxHeight: "600px", // Increase max height for better space management
              overflowY: "auto",
            }}
          >
            <h5 className="mb-3 font-weight-bold" style={{ color: "#4a4a4a" }}>
              Messages
            </h5>
            <div
              style={{
                paddingRight: "10px",
                maxHeight: "500px",
                overflowY: "auto",
                animation: "fadeIn 1s ease-in-out", // Smooth animation for message loading
              }}
            >
              {message.map((msg, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: msg.type === 1 ? "flex-end" : "flex-start",
                    marginBottom: "15px", // Added more spacing for a cleaner layout
                  }}
                >
                  <div
                    style={{
                      backgroundColor: msg.type === 1 ? "#e0ffe0" : "#f4f4f4", // Lighter backgrounds
                      borderRadius: "15px", // Soft rounded corners
                      padding: "12px 20px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)", // Soft shadow for depth
                      maxWidth: "70%",
                      wordWrap: "break-word", // Prevent text overflow
                      transition: "all 0.3s ease-in-out", // Smooth transition on hover
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        color: "#333",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {msg.message}
                    </p>
                    <small style={{ color: "#777", fontStyle: "italic" }}>
                      {new Date(msg.timestamp).toLocaleString()}
                    </small>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div
            className="d-flex flex-column flex-md-row w-full align-items-start align-items-md-center position-relative"
            style={{
              marginTop: "20px", // Added spacing for separation from messages
            }}
          >
            <textarea
              className="form-control reply-input mb-3 mb-md-0 me-md-3 "
              placeholder="Write your reply here..."
              onChange={e => setReply(e.target.value)}
              value={reply}
              {...(isDisabled ? { disabled: true } : {})}
              style={{
                resize: "none",
                borderRadius: "8px",
                padding: "12px 15px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                border: "1px solid #ddd",
                flex: 1,
                height: "auto",
                fontSize: "14px", // Adjusted font size for consistency
                transition: "box-shadow 0.3s ease-in-out",
              }}
              onFocus={e => {
                e.target.style.boxShadow = "0 0 10px rgba(0, 123, 255, 0.3)"
              }}
              onBlur={e => {
                e.target.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)"
              }}
            />
            <button
              className={`btn btn-primary ${reply === "" ? "disabled" : ""}`}
              style={{
                display: isDisabled ? "none" : "block",
                backgroundColor: "#007bff",
                border: "none",
                padding: "12px 20px",
                borderRadius: "8px",
                boxShadow: "0 4px 6px rgba(0, 123, 255, 0.2)",
                transition:
                  "background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                position: "absolute",
                bottom: "10px",
                right: "20px", // Slightly adjusted button position
                zIndex: "1",
              }}
              onClick={() => sendReply()}
              onMouseOver={e => {
                e.target.style.backgroundColor = "#0056b3"
                e.target.style.boxShadow = "0 6px 8px rgba(0, 123, 255, 0.4)"
              }}
              onMouseOut={e => {
                e.target.style.backgroundColor = "#007bff"
                e.target.style.boxShadow = "0 4px 6px rgba(0, 123, 255, 0.2)"
              }}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QueryDetail
