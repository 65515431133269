import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Swal from "sweetalert2"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { toast, ToastContainer } from "react-toastify"
import { CSSTransition } from "react-transition-group"
import {
  acceptOrder,
  getCategories,
  getItemsDetails,
  getMaterials,
  getRetailOrderDetails,
  rejectOrder,
  storeNotification,
} from "services/ApiService"
import Items from "./Items"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons"
import Loader from "components/Common/Loader"

// import Swal from "sweetalert2"
// import "sweetalert2/dist/sweetalert2.min.css"

function RetailOrders() {
  const [data, setData] = useState([])
  const [items, setItems] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [showItemsDetails, setShowItemsDetails] = useState(false)
  const [isForm, setIsForm] = useState(
    parseInt(localStorage.getItem("isForm")) || 1
  )
  const [activeTab, setActiveTab] = useState(localStorage.getItem("activeTab"))
  const [categories, setCategory] = useState([])
  const [materials, setMaterials] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  console.log("isForm from LS...", isForm)
  // var navigate = useNavigate()

  // State to toggle between tabs
  // const [isOpen, setIsOpen] = useEffect(true)

  const fetchData = () => {
    getRetailOrderDetails()
      .then(res => {
        // console.log("reponse", res)
        setData(res.data)
        console.log("From Api data", res.data)
        setIsLoading(false)
      })
      .catch(error => {
        console.error("Error fetching order details:", error)
        toast.error("Failed to fetch order details")
      })
  }

  const fetchMaterial = () => {
    getMaterials()
      .then(res => {
        // console.log("reponse of materials..", res.data)
        setMaterials(res.data)
      })
      .catch(error => {
        console.error("Error fetching order details:", error)
        toast.error("Failed to fetch order details")
      })
  }

  const fetchCategories = () => {
    getCategories()
      .then(res => {
        // console.log("reponse of categoriess..", res.data)
        setCategory(res.data)
      })
      .catch(error => {
        console.error("Error fetching order details:", error)
        toast.error("Failed to fetch order details")
      })
  }

  // console.log("data for cehck :- ", data)
  useEffect(() => {
    fetchData()
    fetchMaterial()
    fetchCategories()
  }, [])
  console.log("From Var data", data)

  const handleViewDetails = value => {
    // console.log("row.original data :- ", value)
    getItemsDetails(value.orderId)
      .then(res => {
        setItems(res.data)
        setShowItemsDetails(true)
      })
      .catch(error => {
        console.error("Error fetching item details:", error)
        toast.error("Failed to fetch item details")
      })
  }

  // console.log("Items check.......", items)

  var handleAcceptOrder = value => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to accept this order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, accept it!",
    }).then(result => {
      if (result.isConfirmed) {
        acceptOrder(value.orderId)
          .then(res => {
            storeNotification(value.orderId).then(res => {
              fetchData()
            })
            Swal.fire("Accepted!", "The order has been accepted.", "success")
          })
          .catch(err => {
            Swal.fire(
              "Error!",
              "There was an issue accepting the order.",
              "error"
            )
          })
      }
    })
  }

  // Reject Order
  var handleRejectOrder = value => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to reject this order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reject it!",
      input: "text", // Add input field
      inputPlaceholder: "Enter reason for cancellation",
      inputValidator: note => {
        if (!note) {
          return "You need to write something!"
        }
      },
    }).then(result => {
      if (result.isConfirmed && result.value) {
        const cancellationNote = result.value // capture the entered note
        var reqObj = {
          note: cancellationNote,
          orderId: value.orderId,
        }
        console.log("cancel OBJECt", reqObj)
        rejectOrder(reqObj)
          .then(res => {
            storeNotification(value.orderId).then(res => {
              fetchData()
            })
            Swal.fire("Rejected!", "The order has been rejected.", "success")
          })
          .catch(err => {
            Swal.fire(
              "Error!",
              "There was an issue rejecting the order.",
              "error"
            )
          })
      }
    })
  }

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "User Name",
        accessor: "userName",
      },
      {
        Header: "Company Name",
        accessor: "companyName",
      },
      
      {
        Header: "Mobile / Email",
        accessor: "contact",
      },
      {
        Header: "View Items",

        Cell: ({ row }) => (
          <div className="position-relative">
            {row.original.isOrderOpened == 0 ? (
              <span
                className="position-absolute badge bg-success text-light"
                style={{
                  top: "-5px", // Adjusts vertical position (above the button)
                  right: "-5px", // Adjusts horizontal position (to the right)
                  fontSize: "8px", // Smaller text for a badge-like appearance
                  padding: "2px 3px", // Compact padding
                  borderRadius: "50%", // Circular badge
                  zIndex: 1, // Ensures it stays above the button
                }}
              >
                new
              </span>
            ) : (
              ""
            )}
            <button
              className="btn p-0 text-black d-inline-block font-size-20 text-center border-0"
              style={{
                transition: "none", // Disable any transition effect
                transform: "none",
                borderRadius: "5px",
              }}
              onClick={() => handleViewDetails(row.original)}
            >
              <i className="mdi mdi-eye" style={{ lineHeight: "20px" }}></i>
            </button>
          </div>
        ),
      },
      {
        Header: "Order Name",
        accessor: "orderName",
      },
      {
        Header: "Order Type",
        // accessor: "isPickup",
        Cell: ({ row }) => (
          <span className="font-size-12">
            {row.original.isPickup ? "Pickup" : "Delivery"}
          </span>
        ),
      },
      {
        Header: "Order Id",
        accessor: "orderId",
      },

      // {
      //   Header: "Action",

      //   Cell: ({ row }) =>
      //     row.original.isAccept ? (
      //       <div>
      //         <span
      //           className="font-size-11 badge-soft-success badge bg-secondary m-1 px-2 py-1"
      //           style={{ borderRadius: "5px" }}
      //         >
      //           Accepted
      //         </span>

      //         <button
      //           className="badge bg-danger d-inline-block font-size-11 text-center  border-0"
      //           style={{
      //             padding: "8px 16px",
      //             minWidth: "100px",
      //             borderRadius: "15px",
      //           }}
      //           onClick={() => handleRejectOrder(row.original)}
      //         >
      //           Cancel Order
      //         </button>
      //       </div>
      //     ) : (
      //       <button
      //         className="badge bg-success d-inline-block font-size-11 text-center  border-0"
      //         style={{
      //           padding: "8px 16px",
      //           minWidth: "100px",
      //           borderRadius: "15px",
      //         }}
      //         onClick={() => handleAcceptOrder(row.original)}
      //       >
      //         Accept Order
      //       </button>
      //     ),
      // },
      {
        Header: "Action",

        Cell: ({ row }) =>
          row.original.type === -1 || row.original.type === 6 ? (
            <div>
              <button
                className="badge bg-success d-inline-block font-size-11 text-center  border-0 mx-1"
                style={{
                  padding: "8px 16px",
                  minWidth: "50px",
                  borderRadius: "15px",
                }}
                onClick={() => handleAcceptOrder(row.original)}
              >
                Accept
              </button>

              <button
                className="badge bg-danger d-inline-block font-size-11 text-center  border-0"
                style={{
                  padding: "8px 16px",
                  minWidth: "50px",
                  borderRadius: "15px",
                }}
                onClick={() => handleRejectOrder(row.original)}
              >
                Cancel
              </button>
            </div>
          ) : row.original.type === 0 ? (
            <span
              className="font-size-11 badge-soft-danger badge bg-secondary m-1 px-2 py-1"
              style={{ borderRadius: "5px" }}
            >
              Declined
            </span>
          ) : (
            <div>
              <span
                className="font-size-11 badge-soft-success badge bg-secondary m-1 px-2 py-1"
                style={{ borderRadius: "5px" }}
              >
                Accepted
              </span>
              <button
                className="badge bg-danger d-inline-block font-size-11 text-center  border-0"
                style={{
                  padding: "8px 16px",
                  minWidth: "50px",
                  borderRadius: "15px",
                }}
                onClick={() => handleRejectOrder(row.original)}
              >
                Cancel
              </button>
            </div>
          ),
      },
    ]

    // Conditionally add the GST Number column if the active tab is "withGst"

    return baseColumns
  })

  // handle form data status
  const handleFormOrder = () => {
    localStorage.setItem("isForm", 1)
    setIsForm(1)
  }
  // handle Img data status
  const handleImageOrder = () => {
    localStorage.setItem("isForm", 2)
    setIsForm(2)
  }

  const handleNoteOrder = () => {
    localStorage.setItem("isForm", 3)
    setIsForm(3)
  }

  // console.log("Is Foem status", isForm)

  // handle Tab  chnage
  const handleTabClick = tabName => {
    localStorage.setItem("activeTab", tabName)
    setActiveTab(tabName)
  }

  // console.log("Active Tab...", activeTab)

  // var hey = true

  const filterData = useMemo(() => {
    const orderList =
      isForm === 1
        ? data.filter(order => order.orderType === 1)
        : isForm === 2
        ? data.filter(order => order.orderType === 2)
        : data.filter(order => order.orderType === 3)
    console.log(" orderlisttt.....", orderList)
    return orderList.filter(order =>
      activeTab === "pending"
        ? order.type === -1
        : activeTab === "accepted"
        ? order.type === 2 ||
          order.type === 3 ||
          order.type === 4 ||
          order.type === 7
        : activeTab === "cancelled"
        ? order.type === 0
        : activeTab === "updated"
        ? order.type === 6
        : order.type === 5 || order.type === 8
    )
  }, [data, isForm, activeTab])

  console.log("Filter...  Order With Status chnage  ", filterData)

  //meta title
  document.title = "Retail Orders | Stelo - Steel Trading Dashboard"

  return (
    <>
      <div className="page-content">
        <Breadcrumbs title="Order" breadcrumbItem="Retail Orders" />
        <ToastContainer />
        <div className="container-fluid">
          {showItemsDetails === false ? (
            <ul className="row m-0 p-0">
              <li
                className={` col-4 col-md-4 col-lg-2 col-xl-2 col-xxl-1 form-tab1 font-size-13 d-flex justify-content-center align-items-center data-tabs   ${
                  isForm === 1 ? "text-white bg-primary" : "text-primary"
                } `}
                onClick={handleFormOrder}
              >
                Form
              </li>
              <li
                className={`col-4 col-md-4 col-lg-2 col-xl-2 col-xxl-1  font-size-13  d-flex justify-content-center align-items-center data-tabs1     ${
                  isForm === 2 ? "text-white bg-primary" : "text-primary"
                } `}
                onClick={handleImageOrder}
              >
                Image
              </li>
              <li
                className={`col-4 col-md-4 col-lg-2 col-xl-2 col-xxl-1 form-tab2 font-size-13  d-flex justify-content-center align-items-center data-tabs2     ${
                  isForm === 3 ? "text-white bg-primary" : "text-primary"
                } `}
                onClick={handleNoteOrder}
              >
                Notes
              </li>
            </ul>
          ) : (
            ""
          )}

          {showItemsDetails === false ? (
            <div className="row tab-div row justify-content-center">
              <div
                className={`col text-center py-2 tab-item ${
                  activeTab === "pending" ? "tab-active" : ""
                }`}
                onClick={() => handleTabClick("pending")}
              >
                Pending
              </div>
              <div
                className={`col text-center py-2 tab-item ${
                  activeTab === "accepted" ? "tab-active" : ""
                }`}
                onClick={() => handleTabClick("accepted")}
              >
                Accepted
              </div>
              <div
                className={`col text-center py-2 tab-item ${
                  activeTab === "cancelled" ? "tab-active" : ""
                }`}
                onClick={() => handleTabClick("cancelled")}
              >
                Cancelled
              </div>
              <div
                className={`col text-center py-2 tab-item ${
                  activeTab === "updated" ? "tab-active" : ""
                }`}
                onClick={() => handleTabClick("updated")}
              >
                Updated
              </div>
              <div
                className={`col text-center py-2 tab-item ${
                  activeTab === "completed" ? "tab-active" : ""
                }`}
                onClick={() => handleTabClick("completed")}
              >
                Completed
              </div>
            </div>
          ) : (
            ""
          )}

          {!showItemsDetails ? (
            <div className="">
              {isLoading ? (
                <Loader />
              ) : filterData.length > 0 ? (
                <TableContainer
                  columns={columns}
                  data={filterData}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  customPageSize={10}
                  isPagination={true}
                  tableClass="align-middle table-nowrap table-check table table-div"
                  theadClass="table-light"
                  paginationDiv="col-12"
                  pagination="justify-content-center pagination pagination-rounded"
                />
              ) : (
                <div className="text-center mt-4">
                  <h5>No Data Found</h5>
                </div>
              )}
            </div>
          ) : (
            <Items
              items={items}
              categories={categories}
              materials={materials}
            />
          )}
        </div>
      </div>

      <div></div>
    </>
  )
}

RetailOrders.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default RetailOrders
