import React from "react"
import { Navigate } from "react-router-dom"
import Login from "../pages/Authentication/Login"
import ForgetPwd from "../pages/Authentication/OtpVerify"
import CategoryTable from "../pages/Category/CategoryTable"
import Query from "../pages/Query/Query"
import DatatableTables from "../pages/Users/UserTable"
import AddCategory from "../pages/AddCategory/AddCategory"
import UpdateCategory from "pages/AddCategory/UpdateCategory"
import RetailOrders from "pages/Order/RetailOrders"
import Items from "pages/Order/Items"
import Material from "pages/Material/Material"
import NewUpdateCat from "pages/AddCategory/NewUpdateCat"
import BulkOrders from "pages/Order/BulkOrders"
import QueryDetail from "pages/Query/QueryDetail"

const authProtectedRoutes = [
  // Tables
  { path: "/users", component: <DatatableTables /> },

  // Category
  { path: "/categories", component: <CategoryTable /> },

  //Query
  { path: "/query", component: <Query /> },
  { path: "/query-details", component: <QueryDetail /> },

  //Orders
  { path: "/retail-orders", component: <RetailOrders /> },
  { path: "/bulk-orders", component: <BulkOrders /> },
  { path: "/items", component: <Items /> },

  //Materials
  { path: "/materials", component: <Material /> },

  //category
  { path: "/add-category", component: <AddCategory /> },
  { path: "/edit-category", component: <UpdateCategory /> },
  { path: "/newedit-category/:id", component: <NewUpdateCat /> },

  {
    path: "/admin",
    exact: true,
    component: <Navigate to="/users" />,
  },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/admin" />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
]

export { authProtectedRoutes, publicRoutes }
